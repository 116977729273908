type Window = {
  fbq: (name: string, action: string, options?: object) => void;
} & typeof globalThis;

export const pageview = () => {
  const window = globalThis as Window;
  window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: any, options = {}) => {
  const window = globalThis as Window;
  (window as any).fbq('track', name, options);
};
