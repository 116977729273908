import 'src/styles/global.css';
import 'src/styles/typography.css';

import ProgressBar from '@badrap/bar-of-progress';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { useEffect, useState } from 'react';

import GlobalMeta from '@/components/Meta/GlobalMeta';

import * as fbq from '../src/lib/fpixel';

// const isProd = process.env.NODE_ENV === 'production';

const progress = new ProgressBar({
  size: 2,
  color: '#FE00A5',
  className: 'bar-of-progress',
  delay: 0,
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60,
          },
        },
      })
  );

  useEffect(() => {
    const handleRouteChange = () => {
      progress.finish();
      fbq.pageview();
    };

    router.events.on('routeChangeStart', progress.start);
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeError', progress.finish);
    return () => {
      router.events.off('routeChangeStart', progress.start);
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeError', progress.finish);
    };
  }, [router]);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <GoogleAnalytics trackPageViews gaMeasurementId="G-CZLQPKT66W" />
        <GlobalMeta />
        <Component {...pageProps} />
      </Hydrate>
    </QueryClientProvider>
  );
};

export default MyApp;
