import merge from 'lodash.merge';

import getEnv from '@/utils/env-getter';

import type { EnvConfigs } from './Config';

const devUrl = process.env.DEV_URL || 'http://localhost:8000';

const raw: EnvConfigs = {
  global: {
    siteName: 'Portal One',
    title: 'Portal One',
    description: 'Time to play Hybrid Games',
    locale: 'en',
    themeColor: '#000000',
    clientId: process.env.CLIENT_SECRET || '',
    cmsUrl: `${devUrl}/api/graphql`,
    cmsBaseUrl: devUrl,
    webCmsUrl: '/api/graphql',
    siteUrl: 'https://portal-one-frontend.vercel.app',
  },
  staging: {
    cmsUrl: 'https://portal-one-backend-staging.clock.uk/api/graphql',
    cmsBaseUrl: 'https://portal-one-backend-staging.clock.uk',
    siteUrl: 'https://portal-one-frontend-staging.vercel.app',
  },
  production: {
    cmsUrl: 'https://portal-one-backend-production.clock.uk/api/graphql',
    cmsBaseUrl: 'https://portal-one-backend-production.clock.uk',
    siteUrl: 'https://portalone.com',
  },
};

type Config = (typeof raw)['global'];

const config: Config = merge(raw.global, raw[getEnv()] || {});

export default config;
