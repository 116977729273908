import config from 'config';
import { DefaultSeo } from 'next-seo';

const GlobalMeta = () => {
  const { title, siteName, description, themeColor } = config;
  const optional: { [key: string]: string } = {
    'application-name': siteName,
    viewport: 'width=device-width, initial-scale=1, viewport-fit=cover',
    'theme-color': themeColor,
  };

  return (
    <DefaultSeo
      titleTemplate={`%s | ${title}`}
      twitter={{
        cardType: 'summary_large_image',
      }}
      title={title}
      description={description}
      openGraph={{
        type: 'website',
        title,
        description,
        site_name: siteName,
        images: [
          {
            url: '/assets/meta/og.png',
            width: 1200,
            height: 600,
            alt: title,
          },
        ],
      }}
      additionalLinkTags={[
        ...[16, 32].map((size) => ({
          rel: 'icon',
          type: 'image/png',
          sizes: `${size}x${size}`,
          href: `/assets/meta/favicon-${size}x${size}.png`,
        })),
        {
          rel: 'apple-touch-icon',
          href: `/assets/meta/apple-touch-icon.png`,
          type: 'image/png',
        },
        {
          rel: 'shortcut icon',
          href: '/assets/meta/favicon.ico',
        },
      ]}
      additionalMetaTags={[
        ...Object.keys(optional).map((name) => {
          return {
            property: name,
            content: optional[name] as string,
          };
        }),
      ]}
    />
  );
};

export default GlobalMeta;
